import Vue from 'vue';
import gql from 'graphql-tag';
import { ApolloError } from 'apollo-boost';

import apollo from '@/services/apollo';
import DoublePaneLayout from '@/components/Admin/DoublePaneLayout/DoublePaneLayout';
import Button from '@/components/Admin/Button/Button';
import './Users.css';
import firebase from '@/services/firebase';

const initialFormValues = {
  accessLevel: 1,
  email: '',
  firstName: '',
  lastName: '',
};

export interface UserModel {
  accessLevel?: number;
  created?: string;
  createdBy?: string;
  email: string;
  id?: string;
  isClient?: boolean;
  firstName: string;
  lastName: string;
  modified?: string;
  phone?: string[];
}

const userAccessLevels = [
  {
    id: 10,
    name: 'Admin',
  },
  {
    id: 7,
    name: 'Editor',
  },
  {
    id: 5,
    name: 'Team Member',
  },
  {
    id: 1,
    name: 'Client',
  },
];

const UserSidebarItem = (context: any) => {
  const user = context.props.item as UserModel;

  const levelTxt = userAccessLevels.find(
    i => i.id === parseInt(user?.accessLevel as any, 10),
  )?.name || '';

  return (
    <li class="sidebar-item">
      <router-link
        class="user-item"
        to={`/admin/settings/users/${user.id}`}
      >
        <span>{user.firstName} {user.lastName}</span>
        <span>{levelTxt}</span>
      </router-link>
    </li>
  );
};

export interface UsersData {
  actions: {
    sendInvitationEmail: boolean;
  };
  currentUser: UserModel | null;
  formData: {
    accessLevel: number;
    email: string;
    firstName: string;
    lastName: string;
  };
  formError: string;
  formMessage: string;
  loading: boolean;
  submissionInProgress: boolean;
  users: UserModel[];
}

const Users = Vue.extend({
  data(): UsersData {
    return {
      actions: {
        sendInvitationEmail: false,
      },
      currentUser: null,
      formData: { ...initialFormValues },
      formError: '',
      formMessage: '',
      loading: true,
      submissionInProgress: false,
      users: [],
    };
  },

  methods: {
    handleAddNewUser(event: Event) {
      event.preventDefault();

      this.submissionInProgress = true;
      this.formError = '';
      this.formMessage = '';

      const isEdit = Boolean(this.currentUser);

      const addUserMutation = gql`
        mutation addUser(
          $accessLevel: Int!
          $email: String!
          $firstName: String!
          $lastName: String!
        ) {
          addUser(
            accessLevel: $accessLevel
            email: $email
            firstName: $firstName
            lastName: $lastName
          ) {
            id
          }
        }
      `;

      const editUserMutation = gql`
        mutation editUser(
          $accessLevel: Int!
          $email: String!
          $id: String!
          $firstName: String!
          $lastName: String!
        ) {
          editUser(
            accessLevel: $accessLevel
            email: $email
            firstName: $firstName
            id: $id
            lastName: $lastName
          ) {
            ok
          }
        }
      `;

      const variables: any = {
        ...this.formData,
        accessLevel: parseInt(this.formData.accessLevel as any, 10),
      };

      if (this.currentUser) {
        variables.id = this.$route.params.id;
      }

      apollo.mutate({
        mutation: isEdit ? editUserMutation : addUserMutation,
        variables,
      })
        .then(() => {
          this.loadUsers();

          if (this.currentUser) {
            this.formMessage = 'User updated';
          } else {
            this.formMessage = 'User created';
            this.formData = { ...initialFormValues };
          }
        })
        .catch((err: ApolloError) => {
          this.formError = err.graphQLErrors.map(({ message }) => message).join(' ');
        })
        .finally(() => {
          this.submissionInProgress = false;
        });
    },

    loadUsers() {
      this.loading = true;

      const query = gql`
        {
          getUsers{
            accessLevel
            email
            id
            firstName
            lastName
          }
        }
      `;

      apollo.query({
        fetchPolicy: 'network-only',
        query,
      })
        .then(({ data }) => {
          this.users = data?.getUsers || [];


          this.setupEditUser();
        })
        .catch((err) => {
          console.log('Error loading users => ', err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    sendInvitationEmail(event: Event) {
      event.preventDefault();

      this.actions.sendInvitationEmail = true;

      const email = this.currentUser?.email || '';

      if (!email) return;

      firebase.auth().sendPasswordResetEmail(email)
        .catch((err) => {
          console.log('Error sending password reset email => ', err);
        })
        .finally(() => {
          this.actions.sendInvitationEmail = false;
        });
    },

    setupEditUser() {
      const { id: userId } = this.$route.params;

      if (!userId) {
        this.formData = { ...initialFormValues };
        this.currentUser = null;
        return;
      }

      const user = (this.users as UserModel[]).find(item => item.id === userId);

      if (!user) {
        this.formData = { ...initialFormValues };
        this.currentUser = null;
        return;
      }

      this.currentUser = user;

      this.formData = {
        accessLevel: user.accessLevel || 1,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
      };
    },
  },

  mounted() {
    this.loadUsers();
  },

  name: 'user-settings',

  render() {
    return (
      <DoublePaneLayout
        filterHandler={null}
        renderSidebarItem={UserSidebarItem}
        search={null}
        sidebarLoading={this.loading}
        sidebarItems={this.users}
        sidebarLink={null}
        sortHandler={null}
      >
        <div class="new-user">
          <header class="dashboard-header">
            <h1 class="dashboard-header__title">{
              this.currentUser
                ? 'Edit '
                : 'New '
            }
            User
            </h1>
          </header>

          <form class="dashboard-content" onSubmit={this.handleAddNewUser}>
            {
              this.currentUser && (
                <div class="key-value">
                  <span class="key">Actions</span>
                  <div class="actions value">
                    <a onClick={(event: Event) => this.sendInvitationEmail(event)}>
                      <Button loading={this.actions.sendInvitationEmail} primary>
                        Send Invite Email
                      </Button>
                    </a>
                  </div>
                </div>
              )
            }

            <div class="form-group">
              <label for="firstName">First Name</label>
              <input
                type="text"
                name="firstName"
                v-model={this.formData.firstName}
                id="firstName"
                required
              />
            </div>

            <div class="form-group">
              <label for="lastName">Last Name</label>
              <input
                type="text"
                name="lastName"
                v-model={this.formData.lastName}
                id="lastName"
                required
              />
            </div>

            <div class="form-group">
              {
                !this.currentUser && (
                  <label for="email">Email</label>
                )
              }
              <input
                type="email"
                name="email"
                v-model={this.formData.email}
                hidden={Boolean(this.currentUser)}
                id="time"
                required
              />
            </div>

            <div class="form-group">
              <label for="accessLevel">Access Level</label>

              <select name="accessLevel" id="accessLevel" v-model={this.formData.accessLevel}>
                {userAccessLevels.map(level => (
                  <option value={level.id}>{level.name}</option>
                ))}
              </select>
            </div>

            {
              this.formError && (
                <div class="form-group">
                  <div class="form-error">{this.formError}</div>
                </div>
              )
            }

            {
              this.formMessage && (
                <div class="form-group">
                  <div class="form-success">{this.formMessage}</div>
                </div>
              )
            }

            <div class="form-group">
              <Button type="submit" loading={this.submissionInProgress} primary>
                {this.currentUser ? 'Update' : 'Create'} User
                </Button>
            </div>

          </form>
        </div>

      </DoublePaneLayout>
    );
  },

  watch: {
    $route: 'setupEditUser',
  },
});


export default Users;
